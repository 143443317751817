.App-focus {
  text-align: center;
  background-color: hsl(205, 59%, 52%);
  transition: all 1000ms linear;
  box-sizing: border-box;
  max-width: 100vw;
}

.App-short-rest {
  text-align: center;
  background-color: hsl(155, 59%, 52%);
  /* height: 100vh; */
  transition: all 1000ms linear;
  box-sizing: border-box;

}

.App-long-rest {
  text-align: center;
  background-color: hsl(283, 59%, 52%);
  /* height: 100vh; */
  transition: all 1000ms linear;
  box-sizing: border-box;
}

.text-field {
  margin-left: 10px;
  width: 100%;
  font-weight: 600 !important;
  color: rgba(255, 255, 255, 1) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-body{
  min-height: 500px;
}


.nav {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  padding: 0px 12px;
  max-width: 550px;
  margin: auto;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  color: white;
}

.nav p {
  color: white;
  margin: 0px;
  padding: 10px 0px;
  font-size: 20px;
}

.nav img {
  margin-right: 4px;
}

.nav .logo {
  height: 30px;
}

.nav hr {
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  height: 2px;
}

.nav-button {
  color: white;
  box-shadow: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.9;
  font-size: 15px;
  min-width: 70px;
  height: 30px;
  transition: all 500ms linear;
  text-decoration: none;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  transition: all 500ms linear;
}

.timer-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 12px;
  max-width: 480px;
  margin: auto;
}

.timer-container {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  width: 100%;
  padding: 5px 0px 20px;
  background-color:rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: none;
}

.timer-settings {
  display: flex; 
  justify-content: center;
  width: 100%;
}

.timer-settings button {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
  color: white;
  margin: 0px;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 12px;
  margin: 0px 5px;
  height: 20px;
  cursor: pointer;
  background: none;
  box-shadow: none;
  font-weight: 300px;
  height: 30px;
}

button.selected-button-focus {
  color: white;
  background-color: hsl(205, 39%, 45%);
  transition: all 500ms linear;
  height: 30px;
}


button.selected-button-short-rest {
  color: white;
  background-color: hsl(155, 54%, 45%);
  transition: all 500ms linear;
  height: 30px;
}

button.selected-button-long-rest {
  color: white;
  background-color: hsl(273, 54%, 45%);
  transition: all 500ms linear;
  height: 30px;
}

.timer-container h3 {
  margin: 0;
  padding-top: 30px;
  font-size: 19px; 
  color: white;
  font-weight: 400;
}

.timer-string {
  padding-bottom: 25px;
  color: white;
  margin: 0px;
  font-size: 120px;
}

.timer-button-focus {
  width: 100px;
  /* border: 1px solid white; */
  background-color: white;
  color: hsl(205, 59%, 52%);
  height: 50px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 5px; 
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 500ms linear;

}

.timer-button-short-rest {
  width: 100px;
  /* border: 1px solid white; */
  background-color: white;
  color: hsl(155, 59%, 52%);
  height: 50px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 5px; 
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 500ms linear;
}

.timer-button-long-rest {
  width: 100px;
  /* border: 1px solid white; */
  background-color: white;
  color: hsl(273, 54%, 45%);
  height: 50px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 5px; 
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 500ms linear;

}

.task-input {
  width: 100%;
  margin-top: 20px;
} 

.rest {
  background-color:rgba(255, 255, 255, 0.3);
  height: 240px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center;
  padding: 5px 0px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  transition: all 1000ms linear;
}

.focus {
  background-color:rgba(255, 255, 255, 0.3);
  min-height: 140px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center;
  padding: 5px 0px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  transition: all 1000ms linear;
}

.hr {
  margin-top: 20px;
  max-width: 100%;
  border: 0; 
  border-top: 1px solid white;
}

.rest-title, .rest-subtitle {
  margin: 4px 0px 10px;
}

.task {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* background-color:rgba(255, 255, 255, 0.1); */
}

.task-actions {
  min-width: 92px
}

.task h4 {
  overflow-wrap: anywhere;
  /* max-width: 300px; */
  text-align: left;
  padding-left: 5px;
}

.task-container {
  width: 400px;
  max-width: 95%;
}

.rest-ideas {
  margin-top: 20px;
  text-align: justify;
}

.rest-ideas h4 {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.tasks-title {
  margin: 4px 0px 10px;
}

.task-titles {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.task-ideas {
  margin-top: 20px;
  text-align: justify;
}

.task-ideas-false {
  display: none
}

.task-ideas h4 {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.task-check-completed-false {
  color: white;
}

.task-check-completed-true {
  color: hsl(205, 59%, 52%);
}

.task-check-saved-false {
  color: white;
}

.task-check-saved-true {
  color: hsl(205, 59%, 52%);
}

#description-hidden-focus {
  border: none;
  display: flex;
  justify-content: center;
  transition: all 1000ms linear;
  background-color: hsl(205, 59%, 52%);
  color: hsl(205, 59%, 52%);
}

#description-hidden-shortrest {
  border: none;
  display: flex;
  justify-content: center;
  transition: all 1000ms linear;
  background-color: hsl(155, 59%, 52%);
  color: hsl(155, 59%, 52%);
}

#description-hidden-longrest {
  border: none;
  display: flex;
  justify-content: center;
  transition: all 1000ms linear;
  background-color:hsl(283, 59%, 52%);
  color: hsl(283, 59%, 52%);
}

#description-visible {
  background-color: white;
  border: none;
  display: flex;
  justify-content: center;
  color: rgb(36, 28, 84);
  transition: all 1500ms linear;
}

.description-body {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;

  }

.description-body h2{
  font-size: 25px;
}

.description-body h3{
  font-size: 20px;
  margin-bottom: 0px;
}

.description-body hr {
  height: 0;
  width: 0;
  border: 0;
}

hr.description-visible {
  background-color: hsl(205, 59%, 52%);
  width: 95%;
  margin-bottom: 20px;
  height: 2px;
  margin-left: 0px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description p {
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.description a {
  text-decoration: none;
  color: inherit;
  font-weight: 500
}

li {
  text-align: left;
  font-size: 18px;
  font-weight: 300;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

#footer {
  background-color: white;
  border: none;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: flex-start;
  color: rgb(36, 28, 84);
  transition: all 1500ms linear;
}

.footer-hr {
  border-top: 2px solid hsl(205, 70%, 17%);
  width: 100%;
  margin: 0px 0px 20px 0px;
  height: 2px; 
  padding: 0px;
}

.nav-links {
  display: flex
}

.nav-links a {
  margin-right: 5px;
}

.copyright {
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 10px
}

.sign-out {
  display: flex;
}

.profile-image {
  height: 30px;
  border-radius: 50%;
}